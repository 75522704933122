import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Flex,
    Grid,
    FormControl,
    FormLabel,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    Image,
} from '@chakra-ui/react'
import { AppButton } from '@core/components/AppButton'
import { useGetHeaderInfoQuery, useUpdateGeneralFeedbackMutation } from '@core/app/api/profileApi'
import { Form, Formik } from 'formik'
import feedbacksent from '@core/assets/images/feedback_sent.svg'
import { useTranslation } from 'react-i18next'

interface FeedbackTopProps {
    isMenuFeedback: boolean
    isProfilePage: boolean
    onClose: () => void
    setSelectedFeedback: (val: number | null) => void
    selectedFeedback: number | null
}

function FeedbackTop({
    isMenuFeedback,
    isProfilePage,
    onClose,
    selectedFeedback,
    setSelectedFeedback,
}: FeedbackTopProps) {
    const { t } = useTranslation()
    const { data, refetch, isUninitialized } = useGetHeaderInfoQuery({})
    const [updateGeneralFeedback, { isLoading, error }] = useUpdateGeneralFeedbackMutation()
    const [isFeedbackSent, setIsFeedbackSent] = useState(false)
    const [show, setShow] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => setShow(true), 3 * 60 * 1000)
        return () => clearTimeout(timer)
    }, [])

    const ratings = [0, 1, 2, 3, 4, 5]

    const onCloseTopBar = () => {
        setShow(false)
        updateGeneralFeedback({ url: window.location.href, rating: null, message: '' })
            .unwrap()
            .then(() => {})
            .catch()
            .finally(() => {
                if (!isUninitialized) {
                    try {
                        refetch()
                    } catch (e) {
                        console.warn('Refetch failed:', e)
                    }
                }
            })
        onCloseModal()
    }

    const onCloseModal = () => {
        // setSelectedFeedback(null)
        setIsFeedbackSent(false)
        onClose()
    }

    return (
        <HStack
            w={'full'}
            justifyContent={'end'}
            py={'16px'}
            px={'20px'}
            backgroundColor={'#E9EFFE'}
            userSelect={'none'}
            position={'relative'}
            display={selectedFeedback !== null || !isProfilePage || !data?.askFeedback || !show ? 'none' : 'flex'} // TODO - data?.askFeedback
        >
            {selectedFeedback !== null && (
                <Modal
                    // isCentered
                    size={'xl'}
                    isOpen={selectedFeedback !== null}
                    onClose={onCloseModal}
                    motionPreset="slideInBottom"
                >
                    <ModalOverlay />
                    {isFeedbackSent ? (
                        <ModalContent
                            color={'#1A1A1A'}
                            className={'FeedbackTop__modal-content'}
                            marginBottom={{
                                base: '0',
                                sm: 'auto',
                            }}
                            marginTop="auto"
                        >
                            <ModalHeader
                                pt={{
                                    base: '20px',
                                    sm: '44px',
                                }}
                                px={{
                                    base: '20px',
                                    sm: '48px',
                                }}
                                pb={'0'}
                                fontWeight={'600'}
                                fontSize={{
                                    base: '18px',
                                    sm: '24px',
                                }}
                            >
                                <Image src={feedbacksent} mb={'16px'} />
                                <Text mb={'14px'} fontWeight={600}>
                                    {t('7341')}
                                </Text>
                            </ModalHeader>
                            <ModalCloseButton
                                top={{
                                    base: '14px',
                                    sm: '30px',
                                }}
                                right={{
                                    base: '14px',
                                    sm: '40px',
                                }}
                            />
                            <ModalBody
                                px={{
                                    base: '20px',
                                    sm: '48px',
                                }}
                                pb={'0'}
                            >
                                <Text
                                    fontSize={{
                                        base: '14px',
                                        sm: '16px',
                                    }}
                                >
                                    {t('9478')}
                                </Text>
                            </ModalBody>
                            <ModalFooter
                                px={{
                                    base: '20px',
                                    sm: '48px',
                                }}
                                pb={{
                                    base: '20px',
                                    sm: '44px',
                                }}
                                pt={{
                                    base: '20px',
                                    sm: '40px',
                                }}
                            >
                                <AppButton
                                    width={'100%'}
                                    variant="primary"
                                    onClick={() => {
                                        refetch()
                                        onCloseModal()
                                    }}
                                >
                                    {t('181')}
                                </AppButton>
                            </ModalFooter>
                        </ModalContent>
                    ) : (
                        <Formik
                            initialValues={{
                                rating: selectedFeedback,
                                message: '',
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                const params = {
                                    rating: isMenuFeedback ? null : values.rating,
                                    message: values.message,
                                    url: window.location.href,
                                }
                                updateGeneralFeedback(params)
                                    .unwrap()
                                    .then((res) => {
                                        setIsFeedbackSent(true)
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })
                                    .finally(() => {
                                        setSubmitting(false)
                                        refetch()
                                    })
                            }}
                        >
                            {({ values, setFieldValue, isSubmitting }) => {
                                return (
                                    <Form>
                                        <ModalContent
                                            color={'#1A1A1A'}
                                            className={'FeedbackTop__modal-content'}
                                            marginBottom={{
                                                base: '0',
                                                sm: 'auto',
                                            }}
                                            marginTop="auto"
                                        >
                                            <ModalHeader
                                                pt={{
                                                    base: '20px',
                                                    sm: '44px',
                                                }}
                                                px={{
                                                    base: '20px',
                                                    sm: '48px',
                                                }}
                                                pb={'0'}
                                                fontWeight={'600'}
                                                fontSize={{
                                                    base: '18px',
                                                    sm: '24px',
                                                }}
                                            >
                                                {t('9477')}
                                            </ModalHeader>
                                            <ModalCloseButton
                                                top={{
                                                    base: '14px',
                                                    sm: '30px',
                                                }}
                                                right={{
                                                    base: '14px',
                                                    sm: '40px',
                                                }}
                                            />
                                            <ModalBody
                                                px={{
                                                    base: '20px',
                                                    sm: '48px',
                                                }}
                                                pb={'0'}
                                            >
                                                <Text
                                                    mb={'24px'}
                                                    fontSize={{
                                                        base: '14px',
                                                        sm: '16px',
                                                    }}
                                                >
                                                    {t('9479')}
                                                </Text>
                                                <Text
                                                    display={isMenuFeedback ? 'none' : 'flex'}
                                                    fontSize={'14px'}
                                                    fontWeight={500}
                                                    mb={'12px'}
                                                >
                                                    {t('9480')}
                                                </Text>
                                                <Flex
                                                    display={isMenuFeedback ? 'none' : 'flex'}
                                                    flexDirection={'column'}
                                                    // alignItems={'flex-start'}
                                                    alignItems={{
                                                        base: 'center',
                                                        sm: 'flex-start',
                                                    }}
                                                    mb={'24px'}
                                                >
                                                    <Flex
                                                        flexDirection={'column'}
                                                        width={{
                                                            base: '100%',
                                                            sm: 'auto',
                                                        }}
                                                    >
                                                        <Flex
                                                            gap={{
                                                                base: '4px',
                                                                sm: '16px',
                                                            }}
                                                            mb={'4px'}
                                                            justifyContent={'space-between'}
                                                        >
                                                            {ratings.map((item) => (
                                                                <AppButton
                                                                    key={item}
                                                                    color={selectedFeedback === item && 'inherit'}
                                                                    variant={
                                                                        selectedFeedback === item
                                                                            ? 'primary'
                                                                            : 'outline'
                                                                    }
                                                                    border={'1px solid #D3DEFC'}
                                                                    fontSize={'14px'}
                                                                    fontWeight={'500'}
                                                                    onClick={() => {
                                                                        setSelectedFeedback(item)
                                                                        setFieldValue('rating', item)
                                                                    }}
                                                                >
                                                                    {item}
                                                                </AppButton>
                                                            ))}
                                                        </Flex>
                                                        <Flex justifyContent={'space-between'}>
                                                            <Text color={'#0C0C0D'} fontSize={'12px'} fontWeight={500}>
                                                                {t('9481')} 😞
                                                            </Text>
                                                            <Text color={'#0C0C0D'} fontSize={'12px'} fontWeight={500}>
                                                                {t('9482')} 😎
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <FormControl>
                                                    <FormLabel htmlFor="message" mt={'24px'} mb={'8px'}>
                                                        {t('9484')}{' '}
                                                        {!isMenuFeedback && (
                                                            <Text as="span" opacity={'0.5'} textTransform={'lowercase'}>
                                                                ({t('6149')})
                                                            </Text>
                                                        )}
                                                    </FormLabel>
                                                    <Textarea
                                                        id="message"
                                                        name="message"
                                                        value={values.message}
                                                        onChange={(e) => setFieldValue('message', e.target.value)}
                                                        placeholder={t('9485') as string}
                                                        resize={'none'}
                                                        h={'120px'}
                                                        fontSize={'14px'}
                                                        fontWeight={500}
                                                    />
                                                </FormControl>
                                            </ModalBody>
                                            <ModalFooter
                                                px={{
                                                    base: '20px',
                                                    sm: '48px',
                                                }}
                                                pb={{
                                                    base: '20px',
                                                    sm: '44px',
                                                }}
                                                pt={{
                                                    base: '20px',
                                                    sm: '40px',
                                                }}
                                            >
                                                <AppButton
                                                    type="submit"
                                                    width={'100%'}
                                                    variant="primary"
                                                    isLoading={isSubmitting}
                                                    isDisabled={isMenuFeedback && values.message.trim().length === 0}
                                                >
                                                    {t('7479')}
                                                </AppButton>
                                            </ModalFooter>
                                        </ModalContent>
                                    </Form>
                                )
                            }}
                        </Formik>
                    )}
                </Modal>
            )}
            <Flex
                grow={1}
                justifyContent={'center'}
                alignItems={'center'}
                gap={{
                    base: '16px',
                    lg: '60px',
                }}
                direction={{
                    base: 'column',
                    lg: 'row',
                }}
            >
                <HStack gap={'16px'}>
                    <Text
                        fontSize={'24px'}
                        display={{
                            base: 'none',
                            lg: 'block',
                        }}
                    >
                        🚀
                    </Text>
                    <Box>
                        <Text
                            color={'#0C0C0D'}
                            fontWeight={'600'}
                            textAlign={{
                                base: 'center',
                                lg: 'left',
                            }}
                        >
                            {t('9483')}
                        </Text>
                        <Text
                            color={'#0C0C0D'}
                            fontSize={'12px'}
                            textAlign={{
                                base: 'center',
                                lg: 'left',
                            }}
                        >
                            {t('9480')}
                        </Text>
                    </Box>
                </HStack>
                <Grid
                    templateAreas={{
                        base: `"buttons buttons" "poor  excellent"`,
                        lg: `"poor buttons excellent"`,
                    }}
                    alignItems={'center'}
                    columnGap={'16px'}
                    rowGap={'6px'}
                >
                    <Text gridArea="poor" color={'#0C0C0D'} fontSize={'12px'} fontWeight={500}>
                        {t('9481')} 😞
                    </Text>
                    <Flex
                        justifyContent={'space-between'}
                        gridArea="buttons"
                        gap={{
                            base: '4px',
                            sm: '16px',
                        }}
                    >
                        {ratings.map((item) => (
                            <Button
                                key={item}
                                backgroundColor={'#FFFFFF'}
                                border={'1px solid #D3DEFC'}
                                fontSize={'14px'}
                                fontWeight={'500'}
                                onClick={() => {
                                    setSelectedFeedback(item)
                                }}
                            >
                                {item}
                            </Button>
                        ))}
                    </Flex>
                    <Text gridArea="excellent" color={'#0C0C0D'} fontSize={'12px'} fontWeight={500} justifySelf={'end'}>
                        {t('9482')} 😎
                    </Text>
                </Grid>
            </Flex>
            <Button
                backgroundColor={'transparent'}
                onClick={onCloseTopBar}
                isLoading={isLoading}
                position={{
                    base: 'absolute',
                    lg: 'relative',
                }}
                top={{
                    base: '10px',
                    lg: '0',
                }}
                right={0}
            >
                <i className="fa-solid fa-xmark-large"></i>
            </Button>
        </HStack>
    )
}

export default FeedbackTop
